"use client";

import useOnDropFiles from "@/common/hooks/useOnDropFiles";
import { useAppDispatch, useAppSelector } from "@/store";
import {
  selectFilesProps,
  selectIsFormFirstView,
  setError,
} from "@/store/slices/senderForm.slice";
import { Grid } from "@mui/material";
import dynamic from "next/dynamic";
import { useDropzone } from "react-dropzone";
import {
  MainWrapper,
  ResponsiveContainer,
  SenderFormStyledGrid,
} from "../(pages)/page.styles";
import { selectIsLoggedIn } from "@/store/slices/auth.slice";
import { useEffect } from "react";
import { useRouter } from "next/navigation";
import { ENV_CONSTANTS } from "@/common/constants/env.const";
const HomePromo = dynamic(() => import("./HomePromo"), { ssr: false });

const DropZone = dynamic(() => import("@/components/dropZone"));
const SenderForm = dynamic(() => import("@/components/senderForm/SenderForm"));
const PeachTermsToast = dynamic(
  () => import("@/components/shared/PeachTermsToast"),
);

const HomeBody = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const isFormFirstView = useAppSelector(selectIsFormFirstView);
  const { allFilesTotalSize, allFilesPaths, isAnyFilesSelected } =
    useAppSelector(selectFilesProps);

  const { onDrop } = useOnDropFiles({
    existingFilesTotalSize: allFilesTotalSize,
    allFilesPaths,
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    onError: () =>
      dispatch(
        setError({
          status: "visible",
          message: "message_dialog.error.filePathTooLong",
        }),
      ),
  });

  const isUserLoggedIn = useAppSelector(selectIsLoggedIn);
  const isPrivateWorkspaceEnabled =
    ENV_CONSTANTS.FEATURE_PRIVATE_WORKSPACE_ENABLED;

  useEffect(() => {
    if (isUserLoggedIn && isPrivateWorkspaceEnabled) {
      router.push("/workspaces");
    }
  }, [isUserLoggedIn, isPrivateWorkspaceEnabled, router]);

  return (
    <MainWrapper
      {...getRootProps()}
      data-testid="dti-landingArea"
      data-analytics="landing-area"
    >
      <input
        {...getInputProps()}
        data-testid="drop-input"
        data-analytics="drop-input"
      />
      <DropZone isDragActive={isDragActive} />

      <ResponsiveContainer maxWidth="xl">
        <Grid
          container
          sx={{ paddingLeft: { xl: 15 }, marginBottom: { md: 10 } }}
          flex={1}
        >
          <Grid
            item
            xs={12}
            md={6}
            display={{
              xs: isAnyFilesSelected ? "none" : "flex",
              sm: isAnyFilesSelected ? "none" : "flex",
              md: "flex",
            }}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <HomePromo />
          </Grid>

          <SenderFormStyledGrid
            item
            xs={12}
            md={6}
            $isAnyFilesSelected={isAnyFilesSelected}
          >
            <SenderForm />
          </SenderFormStyledGrid>
        </Grid>
        <PeachTermsToast show={!isAnyFilesSelected && isFormFirstView} />
      </ResponsiveContainer>
    </MainWrapper>
  );
};

export default HomeBody;
